import * as codeActions from './codedata';
import * as customerActions from './customerdata';
import * as authActions from './authentication';
import * as invoiceActions from './invoicedata';
import * as orderActions from './orderdata';
import * as custOrderActions from './customerorderdata';
import * as partydata from './partydata';
import * as prodplandata from './prodplanningdata';
import * as portfoliodata from './portfoliodata';
import * as settingsdata from './settingsdata';
import * as postaldata from './postaldata';
import { getParameters } from './parameterdata';
import { getFilterProducts, getAllProductBundle, getContractPricingUpdateStatus } from './productdata';
import { getContractPricePricingRequests } from './contractpricerequestdata';
import CONFIG from '../config';
import Logger from '../common/logger';
import { parseInteger } from '../common/common';

function processLocationChange(path, getState, hash) {
    const state = getState.getState();
    const user = state.authentication.userProfile;

    if (state.codedata.countryCodes === null && state.codedata.loadingCountryCodes === false) {
        codeActions.getCountryCodes()(getState.dispatch);
    }

    if (state.codedata.palletInfoCodes === null && state.codedata.loadingPalletInfoCodes === false) {
        codeActions.getPalletInfoCodes()(getState.dispatch);
    }

    if (state.portfoliodata.portfolioDescriptions === null && !state.portfoliodata.fetchingPortfolioDescriptions) {
        portfoliodata.getPortfolioDescriptions()(getState.dispatch);
    }

    if (state.parameterdata.parameters === null) {
        getParameters()(getState.dispatch);
    }

    if (path.startsWith('/admin')) {
        if (path.startsWith('/admin/user')) {
            if (!state.customerdata.allCustomers && !state.customerdata.allCustomersFetching)
                customerActions.getAllCustomers()(getState.dispatch);
        }
    }

    if (user.isSalesUser && (path.startsWith('/customers') || path.startsWith('/locations'))) {
        if (state.partydata.parties === null && !state.partydata.loading) {
            partydata.getParties()(getState.dispatch);
        }
    }
    if (path.startsWith('/invoices/edit/')) {
        try {
            let id = parseInteger(path.substr(15));
            if (id <= 0) {
                throw new Error('fail');
            }
            if (!state.invoicedata.invoicesById[id]) {
                invoiceActions.getInvoiceById(id)(getState.dispatch);
            }
        } catch (err) {
            console.log(err);
            Logger.LogError(`Failed to parse invoice id from: ${path}`);
        }
    }
    if (path.startsWith('/customers') && hash != null && hash.endsWith('money')) {
        if (
            state.customerdata.customershipValuations == null &&
            state.customerdata.customershipValuationsFetching === false
        ) {
            customerActions.getValuations(user.selectedPortfolioId)(getState.dispatch);
        }
    }
    if (user.isSalesUser && (path === '/basket' || path.startsWith('/ordernumber/edit'))) {
        if (state.customerdata.customerDescriptions === null && !state.customerdata.getCustomerDescriptionsInProgress)
            customerActions.getCustomerDescriptiveInfo()(getState.dispatch);
    }
    if (user.isSalesUser && state.orderdata.pendingOrders === null && !state.orderdata.isFetchingOrderData) {
        orderActions.getPendingOrders()(getState.dispatch);
    }
    if (
        CONFIG.customerUi &&
        state.customerorderdata.pendingOrders === null &&
        !state.customerorderdata.isFetchingOrderData
    ) {
        custOrderActions.getPendingOrders()(getState.dispatch);
    }
    if (!CONFIG.customerUi && state.productdata.filterProducts === null && !state.productdata.fetchingFilterProducts) {
        getFilterProducts()(getState.dispatch);
    }
    if (!CONFIG.customerUi && state.productdata.productBundles === null && !state.productdata.fetchingProductBundles) {
        getAllProductBundle()(getState.dispatch);
    }
    if (!state.prodplanningdata.capacityReservations && !state.prodplanningdata.loadingCapacityReservations) {
        prodplandata.loadCapacityReservations()(getState.dispatch);
    }
    if (!state.settingsdata.features && !state.settingsdata.fetchingSettings) {
        settingsdata.getSettings()(getState.dispatch);
    }
    if (state.settingsdata.apiKeys && !state.postaldata.fetchingData && !state.postaldata.postalCodes) {
        postaldata.getPostalCodes()(getState.dispatch);
    }
    if (
        !CONFIG.customerUi &&
        state.contractpricetequestdata.pricingRequestsByContractNumberId &&
        !state.contractpricetequestdata.loadingPricingRequests
    ) {
        getContractPricePricingRequests()(getState.dispatch);
    }
    if (
        path.startsWith('/products') &&
        !state.productdata.contractpricingUpdateStatus &&
        !state.productdata.fetchingCpPricingUpdateStatus
    ) {
        getContractPricingUpdateStatus()(getState.dispatch);
    }
}

const dataLoaderMiddleware = (getState) => (next) => async (action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            if (getState.getState().authentication.authenticated) {
                processLocationChange(action.payload.location.pathname, getState, action.payload.location.hash);
            }
            break;
        }
        case authActions.POST_AUTHENTICATION: {
            processLocationChange(
                getState.getState().router.location.pathname,
                getState,
                getState.getState().router.location.hash
            );
            break;
        }
        case settingsdata.APIKEYS_AVAILABLE: {
            processLocationChange(
                getState.getState().router.location.pathname,
                getState,
                getState.getState().router.location.hash
            );
            break;
        }
        default:
            break;
    }
    return next(action);
};

export default dataLoaderMiddleware;
